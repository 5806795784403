<template>
    <div>
        <Dialog
            header="Documentos"
            v-model:visible="displayModal"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :modal="true"
            dismissableMask
            :maximizable="true"
        >
            <DataTable :value="arquivos" responsiveLayout="scroll">
                <Column field="title" header="Nome"></Column>
                <Column field="tamanho" header="Tamanho"></Column>
                <Column field="created" header="Criado"></Column>
                <Column header="Download">
                    <template #body="slotProps">
                        <Button icon="pi pi-download" class="p-button-text" @click="download(slotProps.data)"></Button>
                    </template>
                </Column>
            </DataTable>
        </Dialog>
    </div>
</template>

<script>
import ServiceArquivo from '../shared/serviceArquivos';

export default {
    props: {
        modal: Boolean,
        objeto: Object,
    },
    data() {
        return {
            arquivos: null,
            displayModal: false,
        };
    },
    mounted() {
        if (this.modal) {
            this.displayModal = true;
        }
        this.obterArquivos();
    },
    methods: {
        openModal() {
            this.displayModal = true;
        },
        closeModal() {
            this.displayModal = false;
        },

        obterArquivos() {
            this.$store.dispatch('addRequest');
            ServiceArquivo.obterArquivos(this.objeto.id, 'Solucao').then((response) => {
                if (response.success) {
                    this.arquivos = response.data;
                }
            });
            this.$store.dispatch('removeRequest');
        },

        download(arquivo) {
            this.$store.dispatch('addRequest');
            ServiceArquivo.downloadFileUrl(arquivo.urlDownload).then((response) => {
                if (response?.success) {
                    this.$download(response.data, arquivo.title);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Templates',
                        detail: 'Erro ao realizar download',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
    watch: {
        displayModal() {
            if (!this.displayModal) {
                this.$emit('closeModal');
            }
        },
    },
};
</script>

<style></style>
