<template>
    <div class="card">
        <div class="container text-center mt-5 mb-5">
            <div class="table-responsive my-5">
                <tabela headerStyle="width: 3em" :mostrarPesquisa="false" id="listagemVitrine" :salvarEstado="false" :mostrarExportar="true" :data="data">
                    <template #conteudo>
                        <Column headerStyle="width: 100px">
                            <template #body="slotProps">
                                <btn-visualizar title="Visualizar" @onVisualizar="visualizar" :objeto="slotProps.data"></btn-visualizar>
                                <Button v-if="slotProps.data.temArquivo" title="Contém arquivos" icon="pi pi-file" class="p-button-text p-button-warning" @click="openModal(slotProps.data)"> </Button>
                                <Button v-else title="Não contém arquivos" icon="pi pi-file" class="p-button-text p-button-danger"> </Button>
                            </template>
                        </Column>
                        <Column :header="campo.titulo" :field="campo.campo" v-for="(campo, index) in campos" :key="index" :sortable="true"></Column>
                    </template>
                </tabela>
                <modal-dowloads v-if="modal" :modal="modal" :objeto="objeto" @closeModal="closeModal"></modal-dowloads>
            </div>
        </div>
        <Button label="Voltar" @click.stop="voltar" class="ml-2"></Button>
    </div>
</template>

<script>
import BtnVisualizar from '../components/BtnVisualizar.vue';
import ModalDowloads from './ModalDowloads.vue';

export default {
    data() {
        return {
            objeto: null,
            modal: false,
            campos: [
                { campo: 'id', titulo: 'Id' },
                { campo: 'codSolucaoSas', titulo: 'Código SAS' },
                { campo: 'title', titulo: 'Título' },
            ],
            data: [],
        };
    },
    components: {
        BtnVisualizar,
        ModalDowloads,
    },
    mounted() {
        if (this.$route.params.objeto) {
            this.data = JSON.parse(this.$route.params.objeto);
        } else {
            this.voltar()
        }
    },
    methods: {
        voltar() {
           this.$router.push({
                name: `Vitrine`,
           })
        },
        visualizar(objeto) {
            this.$router.push({
                name: `Solucao_Visualizar`,
                params: {
                    id: objeto.id,
                },
                query: {
                    view: 'vitrine',
                },
            });
        },
        openModal(solucao) {
            this.objeto = solucao;
            this.modal = true;
        },
        closeModal() {
            this.modal = false;
        },
        
    },
};
</script>

<style></style>
